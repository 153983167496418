import React from 'react'

import Error from 'components/Error'
import MetaTags from 'components/MetaTags'

const ErrorPage = () => (
  <>
    <MetaTags title="404: page not found | Volume7" />
    <Error />
  </>
)

export default ErrorPage
