import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { largerThan } from 'styles/media'

import Heading from 'components/Heading'
import Section from 'components/Section'
import RichText from 'components/RichText'
import Wrapper from 'components/Wrapper'

const Container = styled.div`
  text-align: center;
  padding-bottom: 3rem;

  ${largerThan.small`
    padding-bottom: 4rem;
  `};

  ${largerThan.medium`
    padding-bottom: 4.5rem;
  `};

  ${largerThan.large`
    padding-bottom: 5.5rem;
  `};

  ${largerThan.xLarge`
    padding-bottom: 6rem;
  `};
`

const Error = () => (
  <Container>
    <Section>
      <Wrapper>
        <Heading size={1}>404</Heading>
        <RichText>
          <p>Sorry, the page you've requested could not be found</p>
          <p>
            <Link to="/">Return to home</Link>
          </p>
        </RichText>
      </Wrapper>
    </Section>
  </Container>
)

export default Error
